<template>
  <v-container class="mb-16" fluid>
    <v-row class="mb-10 justify-center">
      <v-col cols="12" class="bg-card">
        <div class="mx-12 mb-4">
          <v-row>
            <v-col :md="3">
            </v-col>
            <v-col cols="10" :md="6">
              <h2 class="pb-2 mt-14 white--text text-center">
                Empieza ahora tu prueba gratuita
              </h2>
            </v-col>
            <v-col :md="3">
            </v-col>
          </v-row>
          <v-row class="mb-12">
            <v-col :md="3">
            </v-col>
            <v-col :md="6">
              <v-btn
                text
                block
                x-large
                class="btn-cta white--text mt-12 pr-16 mr-16"
              >
                Solicita una demo
              </v-btn>
            </v-col>
            <v-col :md="3">
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CTAInv",

  components: {},
  data: () => ({
  }),
};
</script>

<style scoped>
p {
  line-height: 1.6;
}
.bg-card {
  background: #141e56;
}
.icon-banner {
  background-position: left;
}
.btn-cta {
    background: #5A88FE;
    box-shadow: 0px 1px 18px rgba(255, 255, 255, 0.5);
    border-radius: 40px;
}
</style>
