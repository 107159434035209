<template>
  <v-container class="pa-0" fluid>
    <BannerProyectos />
    <InforControl />
    <ControlProject />
    <Rentabiliza />
    <CTAInv />
  </v-container>
</template>

<script>
  import BannerProyectos from '../components/sections/BannerProyectos.vue'
  import InforControl from '../components/sections/InforControl.vue'
  import ControlProject from '../components/sections/ControlProject.vue'
  import Rentabiliza from '../components/sections/Rentabiliza.vue'
  import CTAInv from '../components/sections/CTAInv.vue'

  export default {
    name: 'Facturacion',

    components: {
      BannerProyectos,
      InforControl,
      ControlProject,
      Rentabiliza,
      CTAInv
    },
  }
</script>