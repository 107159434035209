<template>
  <v-container class="bg-section mb-16" fluid>
    <v-row class="justify-center align-center mt-8 mb-16">
      <v-col cols="6" class="mt-4 mr-6">
        <div class="ml-16">
          <h6 class="ml-16 pl-16">OBTÉN DATOS EN TIEMPO REAL</h6>
          <h2 class="b-6 ml-16 pl-16">
            Controla la información de manera instantánea
          </h2>
          <p class="ml-16 pl-16">
            Obtén el informe de las horas trabajadas. Podrás filtrar por
            proyecto, empleado o tarea así como saber en todo momento qué horas
            han sido facturadas o no.
          </p>
        </div>
      </v-col>
      <v-col cols="5" class="cont-img ml-6">
        <v-col cols="6" class="bg-card text-center justify-center align-center mx-6">
          <v-img
            :src="require(`@/assets/contabilidad/libros-cont.png`)"
            height="160px"
          ></v-img>
          <h6 class="mt-6 my-text">
            Conoce el estado de los proyectos.
          </h6>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "InforControl",

  data: () => ({}),
};
</script>

<style scoped>
.cont-img {
  text-align-last: left;
}
.bg-card {
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
}
.my-text {
    text-align-last: center;
}
</style>
