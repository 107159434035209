<template>
  <v-container class="bg-section mb-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="6" class="mt-4">
        <div class="ml-16 mb-14">
          <h6 class="ml-16 pl-16">CONTROLA TU PROYECTO</h6>
          <h2 class="b-6 ml-16 pl-16">Rentabiliza tus proyectos al máximo</h2>
          <p class="ml-16 pl-16">
            Detalla las tareas de cada proyecto y su responsable, indicando si
            son o no facturables y el precio de cada una de ellas. Y controla
            fácilmente si queda algún importe pendiente de facturar o si has
            superado las horas presupuestadas.
          </p>
        </div>
      </v-col>
      <v-col cols="6" class="cont-img">
        <img
          :src="require('@/assets/project/woman-business.png')"
          contain
          height="320"
          class="ml-12"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Rentabiliza",

  data: () => ({}),
};
</script>

<style scoped>
.cont-img {
  text-align-last: left;
}
</style>
