<template>
  <v-container class="mb-16" fluid>
    <v-row class="mt-16">
      <v-col cols="6">
        <div class="ml-16">
          <v-img
            :src="require('@/assets/project/project-banner.png')"
            class=""
            contain
            height="520"
          />
        </div>
      </v-col>
      <v-col cols="5" class="mr-14">
        <div class="mb-8 mr-16 mt-16 text-center justify-center align-center">
          <h1 class="mb-6">Gestiona tus proyectos de manera más eficiente</h1>
          <p class="pb-8 mt-8">
            Optimiza la gestión de tu tienda de la forma más sencilla, completa
            y automatizada.
          </p>
        </div>
        <v-row class="justify-center align-center">
          <div class="text-center mx-12">
            <v-btn rounded x-large class="mainButton" dark>
              Probar Gratis
            </v-btn>
          </div>
          <div class="text-center ml-10">
            <v-btn rounded x-large color="primaryLint" class="red--text" dark>
              Contactarme
            </v-btn>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "BannerProyectos",

  components: {},
  data: () => ({}),
};
</script>

<style scoped>
.bg-card {
  background: #141e56;
  border-radius: 20px;
}
.icon-banner {
  background-position: left;
}
</style>
