<template>
  <v-container class="bg-section my-16" fluid>
    <v-row class="justify-center align-center mb-14">
      <v-col cols="6" class="cont-img">
        <img
          :src="require('@/assets/project/control-project.png')"
          contain
          height="420"
          class="mr-16"
        />
      </v-col>
      <v-col cols="6" class="mt-4">
        <div class="mr-16 mb-14">
          <h6 class="mr-16 pr-16">PLANIFICA Y GESTIONA</h6>
          <h2 class="b-6 mr-16 pr-16">
            Mantén el control de tus proyectos fácilmente
          </h2>
          <p class="mr-16 pr-16">
            Recibe notificaciones cuando tu almacén está bajo mínimos o cuando
            tienes demasiado stock. Visualiza la información actualizada de tus
            ventas y consulta el histórico para hacer previsiones.
          </p>
          <v-list-item-group color="primary" class="mx-2 mt-6">
            <v-list-item v-for="(item, i) in points" :key="i" class="mb-4">
              <v-list-item-icon class="mr-3">
                <div color="white">
                  <v-img
                    :src="require('@/assets/icons/point-b.svg')"
                    contain
                    height="20"
                  />
                </div>
              </v-list-item-icon>
              <v-list-item-content style="display: inline">
                <h5>{{ item.title }}</h5>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ControlProject",

  data: () => ({
      points: [
      {
        title: "Planifica y gestiona las horas imputables de cada proyecto, detallando el responsable y la tarea."
      },
      {
        title: "Coordina todas las tareas entre los miembros del equipo."
      }
    ],
  }),
};
</script>

<style scoped>
.cont-img {
  text-align-last: right;
}
</style>
